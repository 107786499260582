import * as React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/layout'
import Seo from '../../components/seo'
import { Box } from '@mui/system'
import ContentContainer from '../../components/content-container'

const ResultadoIndex = ({ params }) => (
  <>
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.primary.dark,
        color: (theme) => theme.palette.primary.contrastText,
      }}
    >
      <Layout>
        <Seo title="Loading Resultado page" />
        <ContentContainer>
          <>Loading</>
          <Link to="/">Go back to the homepage</Link>
        </ContentContainer>
      </Layout>
    </Box>
  </>
)

export default ResultadoIndex
